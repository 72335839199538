// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-archive-tsx": () => import("./../../../src/pages/archive.tsx" /* webpackChunkName: "component---src-pages-archive-tsx" */),
  "component---src-pages-digital-tsx": () => import("./../../../src/pages/digital.tsx" /* webpackChunkName: "component---src-pages-digital-tsx" */),
  "component---src-pages-donate-tsx": () => import("./../../../src/pages/donate.tsx" /* webpackChunkName: "component---src-pages-donate-tsx" */),
  "component---src-pages-donation-confirmation-tsx": () => import("./../../../src/pages/donation-confirmation.tsx" /* webpackChunkName: "component---src-pages-donation-confirmation-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kids-tsx": () => import("./../../../src/pages/kids.tsx" /* webpackChunkName: "component---src-pages-kids-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-opportunities-tsx": () => import("./../../../src/pages/opportunities.tsx" /* webpackChunkName: "component---src-pages-opportunities-tsx" */),
  "component---src-pages-papers-tsx": () => import("./../../../src/pages/papers.tsx" /* webpackChunkName: "component---src-pages-papers-tsx" */),
  "component---src-pages-people-tsx": () => import("./../../../src/pages/people.tsx" /* webpackChunkName: "component---src-pages-people-tsx" */),
  "component---src-pages-talks-tsx": () => import("./../../../src/pages/talks.tsx" /* webpackChunkName: "component---src-pages-talks-tsx" */),
  "component---src-pages-visit-us-tsx": () => import("./../../../src/pages/visit-us.tsx" /* webpackChunkName: "component---src-pages-visit-us-tsx" */),
  "component---src-pages-whats-on-tsx": () => import("./../../../src/pages/whats-on.tsx" /* webpackChunkName: "component---src-pages-whats-on-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-creative-tsx": () => import("./../../../src/templates/creative.tsx" /* webpackChunkName: "component---src-templates-creative-tsx" */),
  "component---src-templates-digital-tsx": () => import("./../../../src/templates/digital.tsx" /* webpackChunkName: "component---src-templates-digital-tsx" */),
  "component---src-templates-event-series-tsx": () => import("./../../../src/templates/event-series.tsx" /* webpackChunkName: "component---src-templates-event-series-tsx" */),
  "component---src-templates-event-tsx": () => import("./../../../src/templates/event.tsx" /* webpackChunkName: "component---src-templates-event-tsx" */),
  "component---src-templates-exhibition-tsx": () => import("./../../../src/templates/exhibition.tsx" /* webpackChunkName: "component---src-templates-exhibition-tsx" */),
  "component---src-templates-paper-tsx": () => import("./../../../src/templates/paper.tsx" /* webpackChunkName: "component---src-templates-paper-tsx" */),
  "component---src-templates-program-page-tsx": () => import("./../../../src/templates/program-page.tsx" /* webpackChunkName: "component---src-templates-program-page-tsx" */),
  "component---src-templates-special-event-tsx": () => import("./../../../src/templates/special-event.tsx" /* webpackChunkName: "component---src-templates-special-event-tsx" */)
}

