import * as React from "react"

interface IconSpotProps {
  fill: string
}

export const IconSpot = ({ fill: fill }: IconSpotProps) => (
  <svg className="w-auto h-full ml-auto" id="Outline" xmlns="http://www.w3.org/2000/svg" width="122.0747" height="69" viewBox="0 0 122.0747 69">
  <path d="M52.1909,38.0383H7.9615c-2.9317,0-5.3073,2.3756-5.3073,5.3073s2.3756,5.3073,5.3073,5.3073h44.2294c2.9317,0,5.3073-2.3755,5.3073-5.3073s-2.3756-5.3073-5.3073-5.3073Z" fill="none" strokeWidth="0" />
  <path d="M38.0377,61.0374c0,2.9317,2.3756,5.3073,5.3073,5.3073s5.3073-2.3756,5.3073-5.3073v-9.7302h-10.6146v9.7302Z" fill="none" strokeWidth="0" />
  <polygon points="35.3835 27.1822 27.1815 35.3841 35.3835 35.3841 35.3835 27.1822" fill="none" strokeWidth="0" />
  <path d="M47.0985,11.715c2.0721-2.0727,2.0721-5.4331,0-7.5058-2.0732-2.0732-5.4337-2.0732-7.5069,0l-31.1754,31.1749h15.0135l23.6688-23.6691Z" fill="none" strokeWidth="0" />
  <polygon points="38.0377 35.3841 48.6523 35.3841 48.6523 13.9135 38.0377 24.528 38.0377 35.3841" fill="none" strokeWidth="0" />
  <path d="M95.3519,35.3841h9.9144c.727,0,1.4279.1059,2.097.2885l-15.0419-30.0839c-1.3098-2.6213-4.4985-3.6833-7.1203-2.374-2.6218,1.3109-3.6844,4.499-2.3735,7.1208l12.5243,25.0486Z" fill="none" strokeWidth="0" />
  <path d="M62.4846,46.2745l-6.1946,12.3895c-1.3109,2.6218-.2484,5.8094,2.3735,7.1203.7623.3812,1.5711.5615,2.3691.5615,1.947,0,3.8215-1.0755,4.7512-2.9349l6.0519-12.1037h-1.9529c-3.3618,0-6.2305-2.0865-7.3981-5.0327Z" fill="none" strokeWidth="0" />
  <polygon points="92.3851 35.3841 87.5745 25.7629 82.7639 35.3841 92.3851 35.3841" fill="none" strokeWidth="0" />
  <path d="M110.5735,43.3456c0-2.9317-2.3756-5.3073-5.3073-5.3073h-35.3835c-2.9317,0-5.3073,2.3756-5.3073,5.3073s2.3756,5.3073,5.3073,5.3073h35.3835c2.9317,0,5.3073-2.3755,5.3073-5.3073Z" fill="none" strokeWidth="0" />
  <path d="M112.6644,46.2746c-1.1675,2.946-4.0365,5.0326-7.3981,5.0326h-1.9528l6.0518,12.1037c.9297,1.8605,2.8043,2.9349,4.7512,2.9349.7969,0,1.6078-.1803,2.3691-.5615,2.6218-1.3109,3.6843-4.4985,2.3734-7.1203l-6.1946-12.3896Z" fill="none" strokeWidth="0" />
  <path d="M79.7971,35.3841l6.294-12.588-5.6369-11.2739c-.1042-.2082-.1929-.4199-.2773-.6323l-12.3912,24.7826c.6691-.1826,1.37-.2885,2.097-.2885h9.9144Z" fill="none" strokeWidth="0" />
  <path d="M58.6838,47.9427c.4724-.6659.8488-1.404,1.0998-2.1995l-1.0998,2.1995Z" strokeWidth="0" />
  <path d="M60.1525,43.3456c0-4.397-3.5645-7.9615-7.9615-7.9615h-.8843V7.9621c0-2.0375-.7775-4.075-2.3325-5.6296-3.1077-3.1088-8.1505-3.1088-11.2582,0L2.3324,37.7165c-3.1099,3.1088-3.1099,8.1494,0,11.2582,1.5539,1.5549,3.5915,2.3324,5.6291,2.3324h27.4221v9.7302c0,4.397,3.5645,7.9615,7.9615,7.9615s7.9615-3.5645,7.9615-7.9615v-9.7302h.8843c2.6828,0,5.0505-1.3311,6.4929-3.3644l1.0998-2.1995c.2388-.7568.3689-1.5618.3689-2.3976ZM39.5916,4.2092c2.0732-2.0732,5.4337-2.0732,7.5069,0,2.0721,2.0727,2.0721,5.4331,0,7.5058l-23.6688,23.6691h-15.0135L39.5916,4.2092ZM48.6523,13.9135v21.4706h-10.6146v-10.8561l10.6146-10.6145ZM35.3835,27.1822v8.202h-8.202l8.202-8.202ZM48.6523,61.0374c0,2.9317-2.3756,5.3073-5.3073,5.3073s-5.3073-2.3756-5.3073-5.3073v-9.7302h10.6146v9.7302ZM52.1909,48.6529H7.9615c-2.9317,0-5.3073-2.3755-5.3073-5.3073s2.3756-5.3073,5.3073-5.3073h44.2294c2.9317,0,5.3073,2.3756,5.3073,5.3073s-2.3756,5.3073-5.3073,5.3073Z" fill={fill} strokeWidth="0" />
  <path d="M121.2325,57.4773L94.6948,4.402c-1.9653-3.9327-6.7478-5.5249-10.6805-3.5607-1.6032.8016-2.8097,2.0752-3.5526,3.5645l-.0075-.0038-20.6706,41.3412c-.251.7955-.6274,1.5336-1.0998,2.1995l-4.7673,9.5345c-1.9664,3.9327-.3726,8.7141,3.5601,10.6805,1.1425.5723,2.3573.8422,3.5537.8422,2.9209,0,5.7328-1.6121,7.1268-4.4024l6.6452-13.2904h25.5442l6.6453,13.2904c1.394,2.7903,4.2059,4.4024,7.1268,4.4024,1.1965,0,2.4113-.27,3.5537-.8422,3.9327-1.9664,5.5265-6.7478,3.5602-10.6805ZM85.201,3.2147c2.6218-1.3093,5.8105-.2473,7.1203,2.374l15.0419,30.0839c-.6691-.1826-1.3701-.2885-2.097-.2885h-9.9144l-12.5243-25.0486c-1.3109-2.6218-.2484-5.81,2.3735-7.1208ZM92.3851,35.3841h-9.6212l4.8106-9.6212,4.8106,9.6212ZM80.1769,10.89c.0844.2124.173.4241.2773.6323l5.6369,11.2739-6.294,12.588h-9.9144c-.727,0-1.4279.1059-2.097.2885l12.3912-24.7826ZM65.7837,63.4109c-.9297,1.8594-2.8043,2.9349-4.7512,2.9349-.798,0-1.6068-.1803-2.3691-.5615-2.6218-1.3109-3.6844-4.4985-2.3735-7.1203l6.1946-12.3895c1.1676,2.9461,4.0364,5.0327,7.3981,5.0327h1.9529l-6.0519,12.1037ZM69.8827,48.6529c-2.9317,0-5.3073-2.3755-5.3073-5.3073s2.3756-5.3073,5.3073-5.3073h35.3835c2.9317,0,5.3073,2.3756,5.3073,5.3073s-2.3756,5.3073-5.3073,5.3073h-35.3835ZM116.4856,65.7843c-.7613.3812-1.5723.5615-2.3691.5615-1.9469,0-3.8215-1.0744-4.7512-2.9349l-6.0518-12.1037h1.9528c3.3616,0,6.2306-2.0865,7.3981-5.0326l6.1946,12.3894c1.3109,2.6218.2484,5.8094-2.3734,7.1203Z" fill={fill} strokeWidth="0" />
</svg>
)
